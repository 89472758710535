import Home from './components/Home.vue';
import SignUp from './components/SignUp.vue';
import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './components/Login.vue';
import Add from './components/Add.vue';
import Update from './components/Update.vue';
const routes = [ //yonlendirilecek componentler ekleniyor
    {
        name: "HomePage",
        component: Home,
        path: "/"
    },
    {
        name: "SignUp",
        component: SignUp,
        path: "/sign-up" //url ekliyor
    },
    {
        name: "LoginPage",
        component: LoginPage,
        path: "/login" //url ekliyor
    },
    {
        name: "Add",
        component:Add,
        path: "/add" //url ekliyor
    },
    {
        name: "Update",
        component: Update,
        path: "/update/:id" //url ekliyor
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
<template>
  <Header />
  <h1>Merhaba {{name}}, anasayfaya hoş geldin</h1>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <form class="d-flex">
        <select  class="form-select" >
          <option value="" disabled selected>Gayrimenkul Durumunu Seçin</option>
          <option value="Satılık">Satılık</option>
          <option value="Kiralık">Kiralık</option>
        </select>&nbsp;
        <select  class="form-select" >
          <option disabled selected>Türü Seçin</option>
          <option value="Konut" >Konut</option>
          <option value="Dükkan">İşyeri</option>
          <option value="Arsa">Arsa</option>
        </select>&nbsp;
       
          <input class="form-control me-2" type="search" placeholder="Ara" aria-label="Search">
          <button class="btn btn-outline-success" v-on:click="ara" type="submit"><i class="bi bi-search"></i></button>
        </form>
      
      </div>
  </nav>
  <table>
    <tr class="bold-font" :style="{ color: 'black'}">
   
      <td > id </td>
      <td> tür</td>
      <td> durum</td>
      <td> adres</td>
      <td> yayin tarihi </td>
      <td> İşlemler </td>
      
    </tr>
    
    <tr v-for="item in emlakliste" :key="item.id">
      <td> {{item.id}} </td>
      <td> {{item.tur}}</td>
      <td> {{item.durum}}</td>
       <td> {{item.adres}} </td>
      <td> {{item.yayintarihi}} </td>
      <td align="center"> 
        <th><router-link :to="'/update/'+ item.id"> <button class="guncelle"><i class="bi bi-pencil-square"></i></button></router-link></th>
        
        <th><button class="sil" v-on:click="sil(item.id)"><i class="bi bi-trash3 "></i> </button></th>

       </td>
    </tr>
  </table>
 
</template>
<script>
import Header from './Header.vue';
import axios from 'axios';
export default {
  name: "HomePage",  //homepage componenti import edildiğinde yukarıdaki template gözükecek
  data(){
    return{
      name:'',
      emlakliste:[],
    }
  },
  components:{
    Header
  },
  methods:{
        async sil(id)
        {
            let result=await axios.delete('http://localhost:3000/emlak/'+id)
            console.warn(result.status)
            if(result.status==200)
            {
              this.loaddata();
            }
        },

        async loaddata()
        {
          let user=localStorage.getItem('user-info'); //giriş yapan kullanıcı bilgilerini getir
          this.name=JSON.parse(user).name;
          if(!user)
          {
            this.$router.push({name:'SignUp'})  //kayıtlı kullanıcı yoksa kayıt sayfasına yolluyor
          }

          let result=await axios.get('http://localhost:3000/emlak'); //veritabanını getir
          console.warn(result)
          this.emlakliste=result.data; //veri tabanını emlak listeye at
        }
  },
 async mounted() {
       this.loaddata(); // dom olarak load data yükle
    },
};
</script>

<style>
.bold-font{
    font-weight: bolder;
}
.sil{
  width: 100%;
    height: 100%;
    border: 1px solid black;
    background: red;
    color: white;
    cursor: pointer;
}
.sil:hover{

    background: black;
    color: white;
    cursor: pointer;
}
.guncelle{
  width: 100%;
    height: 100%;
    border: 1px solid black;
    background: rgb(255, 166, 0);
    color: white;
    cursor: pointer;
}
.guncelle:hover{
  
    background: black;
    color: white;
    cursor: pointer;
}
td {
  width: 300px;
  height: 40px;
  border: 2px solid skyblue !important;
}
</style>
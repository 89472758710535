<template>
  <img class="logo" src="../assets/metah.png" />
  <h1>Giriş Yap</h1>
  <div class="login">
    <form>
      <input
        v-on:keyup.enter="login()"
        type="email"
        v-model="email"
        placeholder="E-mail"
        required
      />
      <input
        v-on:keyup.enter="login()"
        type="password"
        v-model="password"
        placeholder="Şifre"
        required
      />
      <button v-on:click="login">Giriş Yap</button>

      <p>
        <router-link to="/sign-up">Kayıt Ol</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LoginPage",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      let result = await axios.get("http://localhost:3000/users", {
        params: {
          email: this.email,
          password: this.password,
        },
      });
      if (result.status == 200 && result.data.length > 0) {
        localStorage.setItem("user-info", JSON.stringify(result.data[0])); //kayıt bilgilerini localde kaydediyor
        this.$router.push({ name: "HomePage" });
      } 
      console.warn(result);
    },
  },

  mounted() {
    let user = localStorage.getItem("user-info");
    if (user) {
      this.$router.push({ name: "HomePage" }); //kayıt olduktan sonra homepage componentine yönlendiriyor
    }
  },
};
</script>

<template>
<router-view />
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

.logo{
    width:200px;
}

.register input, .login input, .add input, .add select {
    width: 320px;
    height: 40px;
    padding-left:20px;
    display: block;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left:auto;
    border: 1px solid #87ceeb;
}
.register button, .login button, .add button{
    width: 320px;
    height: 40px;
    border: 1px solid #87ceeb;
    background: skyblue;
    color: white;
    cursor: pointer;
}

</style>

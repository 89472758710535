<template>
  <Header />
  <h1>Merhaba {{ name }}, Güncelle sayfasına hoş geldin</h1>
  <form class="add">
    <select  class="form-select" v-model="emlakliste.durum">
          <option value="Satılık">Satılık</option>
          <option value="Kiralık">Kiralık</option>
    </select>
    <select  class="form-select" v-model="emlakliste.tur"  aria-label="Default select example" >
          <option value="Konut" >Konut</option>
          <option value="Dükkan">İşyeri</option>
          <option value="Arsa">Arsa</option>
    </select>
    <input v-model="emlakliste.adres" v-on:keyup.enter="eklebutton()" type="text" name="adres" placeholder="Adresi girin" />
    <input v-model="emlakliste.yayintarihi" v-on:keyup.enter="eklebutton()" type="text" name="yayintarihi" placeholder="yayınlanma tarihi girin" />
    <button type="button" v-on:click="guncelle">Güncelle</button>
  </form>
</template>
<script>
import Header from './Header.vue';
import axios from 'axios';
export default {
  name: "Update-item",  //homepage componenti import edildiğinde yukarıdaki template gözükecek
  components:{
        Header
      },
  data(){
    return{
      name:'',
      emlakliste :{
        id:'',
        adres:'',
        tur:'',
        durum:'',
        yayintarihi:''
      }
    }
  },
  methods: {
      async guncelle()
      {
        let result=await axios.put("http://localhost:3000/emlak/"+this.$route.params.id,{
                adres:this.emlakliste.adres,
                tur:this.emlakliste.tur,
                durum:this.emlakliste.durum,
                yayintarihi:this.emlakliste.yayintarihi
            });
            
            if(result.status==200)
            {
                this.$router.push({name:'HomePage'})
            }
      }
  },
  async mounted() 
    {
        let user=localStorage.getItem('user-info');
        if(!user)
        {
            this.$router.push({name:'LoginPage'})  //kayıtlı kullanıcı yoksa kayıt sayfasına yolluyor
        }

        const result= await axios.get('http://localhost:3000/emlak/'+this.$route.params.id)
        console.warn(result.data)
        this.emlakliste=result.data
    },
};
</script>
<template>
  <img class="logo" src="../assets/metah.png" />

  <div class="register">
    <h1>Kayıt Ol</h1>
    <form>
      <input
        v-on:keyup.enter="signup()"
        type="text"
        v-model="name"
        placeholder="Adınız"
        required
      />
      <input
        v-on:keyup.enter="signup()"
        type="email"
        v-model="email"
        placeholder="E-mail"
        required
      />
      <input
        v-on:keyup.enter="signup()"
        type="password"
        v-model="password"
        placeholder="Şifre"
        required
      />

      <button v-on:click="signup">Kayıt Ol</button>
      <p>
        <router-link to="/login">Giriş Yap</router-link>
      </p>
    </form>
  </div>
</template>
<script>
import axios from "axios";
export default {
  // signup değerlerini dışarıya açıyor
  name: "SignUp",
  data() {
    return {
      name: "",
      email: "",
      password: "",
    };
  },
  methods: {
    //axios ile localhosta kayıt yolluyor
    async signup() {
      if (this.name != "" && this.email != "" && this.password != "") {
        let result = await axios.post("http://localhost:3000/users", {
          name: this.name,
          email: this.email,
          password: this.password,
        });

        console.warn(result);
        if (result.status == 201) {
          localStorage.setItem("user-info", JSON.stringify(result.data)); //kayıt bilgilerini localde kaydediyor
          this.$router.push({ name: "HomePage" });
        }
      }
    },
  },
  mounted() {
    let user = localStorage.getItem("user-info");
    if (user) {
      this.$router.push({ name: "HomePage" }); //kayıt olduktan sonra homepage componentine yönlendiriyor
    }
  },
};
</script>

<style>
</style>
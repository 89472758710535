<template>
  <div class="nav">
    <router-link to="/">Anasayfa </router-link>
    <router-link to="/add">Ekle </router-link>
    <a v-on:click="logout" href="#">Çıkış Yap </a>
  </div>
</template>
<script>
export default {
  name: "TheHeader",
  methods:{
      logout()
      {
          console.warn("logout");
          localStorage.clear();
          this.$router.push({name:'LoginPage'})
      }
  }
};
</script>
<style>
.nav {
  background-color: #2c3e50;
  overflow: hidden;
}
.nav a {
  float: left;
  color: white;
  padding: 14px 16px;
  text-align: center;
  font-size:17px;
  text-decoration: none;
  margin-right: 5px;
}
.nav a:hover{
    background: skyblue;
    color:#333;
}
body {
  padding: 0;
  margin: 0;
}
</style>
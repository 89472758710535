<template>
  <Header />
  <h1>Merhaba {{ name }}, Ekle sayfasına hoş geldin</h1>
  <form class="add">
    <select class="form-select" v-model="emlakliste.durum" required>
      <option value="" disabled selected>Gayrimenkul Durumunu Seçin</option>
      <option value="Satılık">Satılık</option>
      <option value="Kiralık">Kiralık</option>
    </select>
    <select class="form-select" v-model="emlakliste.tur" required>
      <option value="" disabled>Gayrimenkul Türünü Seçin</option>
      <option value="Konut" >Konut</option>
      <option value="Dükkan">İşyeri</option>
      <option value="Arsa">Arsa</option>
    </select>
    <input
      v-model="emlakliste.adres"
      v-on:keyup.enter="eklebutton()"
      type="text"
      name="adres"
      placeholder="Adresi girin"
      required
    />
    <input
      v-model="emlakliste.yayintarihi"
      v-on:keyup.enter="eklebutton()"
      type="text"
      name="yayintarihi"
      placeholder="yayınlanma tarihi girin"
      required
    />
    <button  v-on:click="eklebutton">Ekle</button>
  </form>
</template>
<script>
import Header from "./Header.vue";
import axios from "axios";
export default {
  name: "Add-item",
  components: {
    Header,
  },
  data() {
    return {
      name: "",
      emlakliste: {
        adres: "",
        tur: "",
        durum: "",
        yayintarihi: "",
      },
    };
  },

  methods: {
    async eklebutton() {
      if (
        this.emlakliste.adres != "" &&
        this.emlakliste.tur != "" &&
        this.emlakliste.durum != "" &&
        this.emlakliste.yayintarihi != ""
      ) {
          let result = await axios.post("http://localhost:3000/emlak", {
          adres: this.emlakliste.adres,
          tur: this.emlakliste.tur,
          durum: this.emlakliste.durum,
          yayintarihi: this.emlakliste.yayintarihi,
          });
          console.warn("result", result);
          if(result.status==200)
            {
                this.$router.push({name:'HomePage'})
            }
        }
    },
  },
  async mounted() {
    let user = await localStorage.getItem("user-info");
    if (!user) {
      this.$router.push({ name: "LoginPage" }); //kayıtlı kullanıcı yoksa kayıt sayfasına yolluyor
    }
  },
};
</script>